<template>
  <div class="main row">
    <div class="col-md-12">
      <KTCodePreview v-bind:title="'Danh sách khách hàng'">
        <template v-slot:toolbar>
          <div class="row">
            <!-- <div>
              <b-dropdown size="sm" right>
                <template slot="button-content">
                  <i style="font-size: 1rem" class="fas fa-cog"></i>
                  <b>Thao tác</b>
                </template>
                <b-dropdown-item
                  v-if="checkPermission('CUSTOMER_EXPORT')"
                  @click="excelModal"
                >
                  <i style="font-size: 1rem" class="far fa-file-excel mr-1"></i>
                  <span>Xuất Excel</span>
                </b-dropdown-item>
              </b-dropdown>
            </div> -->
            <div v-show="checkPermission('CUSTOMER_INSERT')" class="ml-3">
              <router-link to="/customers/add-customer">
                <b-button size="sm" variant="primary">
                  <i style="font-size: 1rem" class="flaticon2-add-1"></i>
                  <b>Thêm mới</b>
                </b-button>
              </router-link>
            </div>
          </div>
        </template>
        <template v-slot:preview>
          <div class="mb-5">
            <b-row class="mb-3">
              <b-col cols="2">
                <b-input
                  size="sm"
                  placeholder="Tên"
                  v-model="customerName"
                  v-on:keyup.enter="onFilter()"
                />
              </b-col>
              <b-col cols="2">
                <b-input
                  size="sm"
                  placeholder="Điện thoại"
                  v-model="customerPhone"
                  v-focus
                  autocomplete="off"
                  v-on:keyup.enter="onFilter()"
                />
              </b-col>
              <b-col cols="2">
                <date-picker
                  class="form-control-sm"
                  placeholder="Chọn ngày sinh"
                  :config="dateConfig.option"
                  v-model="customerDOB"
                ></date-picker>
              </b-col>
              <b-col cols="2">
                <b-form-select
                  size="sm"
                  required
                  v-model="customerGender"
                  :options="genders"
                  value-field="id"
                  text-field="name"
                  class="select-style"
                >
                </b-form-select>
              </b-col>
            </b-row>
            <b-row>
              <b-col cols="1">
                <b-button
                  style="width: 70px"
                  variant="primary"
                  size="sm"
                  @click="onFilter"
                >
                  Lọc
                </b-button>
              </b-col>
            </b-row>
          </div>

          <b-row>
            <b-col cols="12">
              <b-table
                :items="customers"
                :fields="fields"
                bordered
                hover
                :busy="onLoading"
              >
                <template v-slot:table-busy>
                  <vcl-table
                    :speed="5"
                    :animate="true"
                    :columns="10"
                  ></vcl-table>
                </template>
                <template #cell(index)="data">
                  {{ data.index + 1 }}
                </template>
                <template v-slot:cell(phoneNumber)="row">
                  <a v-bind:href="getLinkDoc(row.item)">
                    <b>
                      {{ row.item.phoneNumber }}
                    </b>
                  </a>
                </template>
                <template v-slot:cell(actions)="row">
                  <b-dropdown size="sm" no-caret right>
                    <template slot="button-content">
                      <i
                        style="font-size: 1rem"
                        class="flaticon2-settings pr-0"
                      ></i>
                    </template>
                    <b-dropdown-item
                      @click="editItem(row.item)"
                      v-if="checkViewOnly()"
                    >
                      <span style="color: #3f4254; font-size: 12px">
                        <i
                          style="font-size: 1rem"
                          class="fas fa-eye icon-color"
                        ></i>
                        &nbsp; Chi tiết
                      </span>
                    </b-dropdown-item>
                    <b-dropdown-item
                      @click="editItem(row.item)"
                      v-if="!checkViewOnly()"
                    >
                      <span style="color: #3f4254; font-size: 12px">
                        <i
                          style="font-size: 1rem"
                          class="flaticon2-pen icon-color"
                        ></i>
                        &nbsp; Cập nhật
                      </span>
                    </b-dropdown-item>
                    <b-dropdown-item
                      @click="showDeleteAlert(row.item)"
                      v-show="checkPermission('USER_DELETE')"
                    >
                      <span style="color: #3f4254; font-size: 12px">
                        <i
                          style="font-size: 1rem; color: #d33"
                          class="flaticon2-rubbish-bin-delete-button"
                        ></i>
                        &nbsp; Xóa
                      </span>
                    </b-dropdown-item>
                  </b-dropdown>
                </template>
              </b-table>
            </b-col>
          </b-row>
          <b-row>
            <b-col>
              <p class="mt-3 text-dark" style="font-weight: 500">
                Tổng số khách hàng:
                {{ totalItem }}
              </p>
            </b-col>
            <div class="d-flex justify-content-end">
              <b-col>
                <b-pagination-nav
                  class="custom-pagination"
                  v-if="numberOfPage >= 2"
                  :link-gen="linkGen"
                  :number-of-pages="numberOfPage"
                  use-router
                  @change="fetchData"
                  align="right"
                  first-class="page-item-first btn btn-icon btn-sm mr-1 my-1"
                  prev-class="page-item-prev btn btn-icon btn-sm mr-.5 my-1"
                  next-class="page-item-next btn btn-icon btn-sm mr-1 my-1 ml-.5"
                  last-class="page-item-last btn btn-icon btn-sm my-1 "
                  page-class="btn btn-icon btn-sm border-0 mr-.5 my-1"
                >
                  <template v-slot:first-text>
                    <i class="ki ki-bold-double-arrow-back icon-xs"></i>
                  </template>
                  <template v-slot:prev-text>
                    <i class="ki ki-bold-arrow-back icon-xs"></i>
                  </template>
                  <template v-slot:next-text>
                    <i class="ki ki-bold-arrow-next icon-xs"></i>
                  </template>
                  <template v-slot:last-text>
                    <i class="ki ki-bold-double-arrow-next icon-xs"></i>
                  </template>
                </b-pagination-nav>
              </b-col>
            </div>
          </b-row>
        </template>
      </KTCodePreview>
    </div>
    <b-modal ref="excel-modal" hide-footer title="Xuất excel" id="excel-modal">
      <v-form ref="form" lazy-validation>
        <div class="mb-4">
          <div class="row">
            <div class="col-6">
              <b-form-group label="Chọn cột cần xuất:">
                <b-form-radio-group
                  id="radio-group-excel-1"
                  v-model="selectedRowExcel"
                  name="radio-sub-component-1"
                  @change.native="showHeader"
                >
                  <b-form-radio value="all">Tất cả</b-form-radio>
                  <b-form-radio value="row">Chọn cột</b-form-radio>
                </b-form-radio-group>
              </b-form-group>
              <b-form-group label="Danh sách cần xuất:">
                <b-form-radio-group
                  id="radio-group-excel-2"
                  v-model="selectedListExcel"
                  name="radio-sub-component-2"
                >
                  <b-form-radio value="current">Trang hiện tại</b-form-radio>
                  <!-- <b-form-radio value="all">Tất cả các trang</b-form-radio> -->
                </b-form-radio-group>
              </b-form-group>
            </div>
            <div class="col-6" v-show="showHeaderExcel">
              <b-form-group>
                <template #label>
                  Cột:<br />
                  <b-form-checkbox
                    v-model="allSelected"
                    :indeterminate="indeterminate"
                    aria-describedby="items"
                    aria-controls="items"
                    @change="toggleAll"
                  >
                    {{ allSelected ? 'Bỏ chọn tất cả' : 'Tất cả' }}
                  </b-form-checkbox>
                </template>
                <b-form-checkbox-group
                  id="items"
                  v-model="selectedHeader"
                  :options="optionsHeader"
                  name="items"
                  stacked
                ></b-form-checkbox-group>
              </b-form-group>
            </div>
          </div>
        </div>

        <b-button
          style="width: 110px"
          variant="primary"
          size="sm"
          @click="reportClick"
          class="mr-3"
        >
          <v-icon small class="text-white" v-b-tooltip>mdi-download</v-icon>
          <strong>Xuất Excel</strong>
        </b-button>

        <b-button
          style="width: 100px"
          variant="secondary"
          size="sm"
          @click="$bvModal.hide('excel-modal')"
        >
          <strong>Hủy</strong>
        </b-button>
      </v-form>
    </b-modal>
  </div>
</template>

<style lang="scss" scoped>
.main ::v-deep {
  .btn.btn-icon.btn-sm,
  .btn-group-sm > .btn.btn-icon {
    height: calc(1.35em + 1.1rem + 2px);
    width: auto;
  }

  .icon:hover {
    background-color: #90c6fc;
  }

  .table th,
  .table td {
    padding: 0.75rem;
    vertical-align: middle;
    border-top: 1px solid #ecf0f3;
  }
  .table.b-table.table-sm
    > thead
    > tr
    > [aria-sort]:not(.b-table-sort-icon-left),
  .table.b-table.table-sm
    > tfoot
    > tr
    > [aria-sort]:not(.b-table-sort-icon-left) {
    background-position: right calc(1.5rem / 2) center;
    padding-right: calc(0.3rem + 0.65em);
  }
  .select-style {
    border-radius: 0.28rem;
    box-shadow: none;
    border: 1px solid #ced4da;
  }
  input.form-control {
    border: 1px solid #ced4da;
  }
  .icon-color {
    color: #464e5f;
  }
}
</style>
<script>
import KTCodePreview from '@/view/content/CodePreview.vue';
import { SET_BREADCRUMB } from '@/core/services/store/modules/breadcrumbs.module';
import Swal from 'sweetalert2';
import ApiService from '@/core/services/api.service';
import { checkPermission } from '../../../utils/saveDataToLocal';
import moment from 'moment';
import { VclTable } from 'vue-content-loading';
// Import datePicker component
import datePicker from 'vue-bootstrap-datetimepicker';
import axios from 'axios';
import {
  formatDate,
  // convertPrice,
  makeToastSuccess,
  makeToastFaile,
} from '@/utils/common';
import { CUSTOMER_TYPE } from '@/utils/enum';
import { BASE_URL, CUSTOMER_GENDER } from '@/utils/constants';

export default {
  data() {
    return {
      dateConfig: {
        option: {
          format: 'DD/MM/YYYY',
          useCurrent: false,
          showClear: true,
          showClose: true,
          minDate: false,
          maxDate: false,
          locale: 'vi',
          sideBySide: true,
        },
      },
      onLoading: false,
      customerName: '',
      customerDOB: '',
      customerPhone: '',
      customerGender: null,
      genders: CUSTOMER_GENDER,
      fields: [
        {
          key: 'index',
          label: 'STT',
          thStyle: { textAlign: 'center', width: '2%' },
          class: 'text-center',
        },
        {
          key: 'phoneNumber',
          label: 'Điện thoại',
          thStyle: { textAlign: 'center', width: '10%' },
        },
        {
          key: 'fullName',
          label: 'Khách hàng',
          thStyle: { textAlign: 'center', width: '20%' },
        },
        // {
        //   key: 'type',
        //   label: 'Loại',
        //   thStyle: { textAlign: 'center', width: '8%' },
        //   class: 'text-center',
        //   formatter: (value) => {
        //     return this.getCustomerType(value);
        //   },
        // },
        {
          key: 'gender',
          label: 'Giới tính',
          thStyle: { textAlign: 'center', width: '6%' },
          class: 'text-center',
          formatter: (value) => {
            return this.getCustomerGender(value);
          },
        },

        // {
        //   key: 'totalBuyedAmount',
        //   label: 'Tổng tiền',
        //   thStyle: { textAlign: 'center' },
        //   tdClass: 'text-right',
        //   formatter: (value) => {
        //     return convertPrice(value);
        //   },
        // },
        // {
        //   key: 'totalBuyedNo',
        //   label: 'Số lần mua',
        //   thStyle: { textAlign: 'center' },
        //   tdClass: 'text-right',
        // },
        // {
        //   key: 'totalPoint',
        //   label: 'Điểm tích lũy',
        //   thStyle: { textAlign: 'center' },
        //   tdClass: 'text-right',
        // },
        // {
        //   key: 'lastedBuyedAt',
        //   label: 'Ngày mua gần nhất',
        //   thStyle: { textAlign: 'center' },
        //   tdClass: 'text-right',
        //   formatter: (value) => {
        //     return formatDate(value, 'DD-MM-YYYY HH:mm:ss');
        //   },
        // },
        {
          key: 'createdAt',
          label: 'Ngày tạo',
          thStyle: { textAlign: 'center', width: '15%' },
          tdClass: 'text-center',
          formatter: (value) => {
            return formatDate(value, 'DD-MM-YYYY HH:mm:ss');
          },
        },
        {
          key: 'actions',
          label: '',
          class: 'text-center',
          thStyle: { width: '15%' },
        },
      ],
      customers: [],
      linkGen: (pageNum) => {
        return pageNum === 1 ? '?' : `?page=${pageNum}`;
      },
      page: 1,
      totalItem: 0,
      selectedRowExcel: 'all',
      selectedListExcel: 'current',
      showHeaderExcel: false,
      indeterminate: false,
      allSelected: false,
      optionsHeader: [],
      selectedHeader: [],
      optionsHeaderDefault: [],
      numberOfPage: 1,
      searchLastName: '',
    };
  },
  components: {
    KTCodePreview,
    VclTable,
    datePicker,
  },
  created() {
    this.fetchData();
  },
  mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [
      { title: 'Khách hàng', route: '/customers' },
      { title: 'Danh sách khách hàng' },
    ]);
  },
  watch: {
    selectedHeader(newVal) {
      // Handle changes in individual flavour checkboxes
      if (newVal.length === 0) {
        this.indeterminate = false;
        this.allSelected = false;
      } else if (newVal.length === this.optionsHeader.length) {
        this.indeterminate = false;
        this.allSelected = true;
      } else {
        this.indeterminate = true;
        this.allSelected = false;
      }
    },
  },
  methods: {
    checkPermission,
    checkViewOnly: function () {
      let check = true;
      if (checkPermission('CUSTOMER_VIEW')) {
        check = true;
      }
      if (checkPermission('CUSTOMER_UPDATE')) {
        check = false;
      }
      return check;
    },
    onFilter() {
      this.$route.query.page = 1;
      this.$router.push({
        name: 'list-customer',
      });
      this.fetchData();
    },
    fetchData: function () {
      this.onLoading = true;
      this.page = this.$route.query.page || 1;
      const params = {
        limit: 10,
        page: this.page,
      };
      if (this.customerName) {
        params['customerName'] = this.customerName.trim();
      }
      if (this.customerPhone) {
        params['customerPhone'] = this.customerPhone.trim();
      }
      if (this.customerDOB) {
        params['customerDOB'] = moment(this.customerDOB, 'DD/MM/YYYY').format(
          'yyyy-MM-DD',
        );
      }
      if (this.customerGender) {
        params['customerGender'] = this.customerGender;
      }

      ApiService.query('customers', {
        params: params,
      })
        .then(({ data }) => {
          this.customers = [];
          const dataReq = data.data;
          this.totalItem = dataReq.paginations.total;
          this.customers = dataReq.data;
          this.numberOfPage = dataReq.paginations.currentPage;
          this.onLoading = false;
        })
        .catch(() => {
          makeToastFaile('Có lỗi xảy ra vui lòng thử lại sau!');
        });
    },
    editItem: function (item) {
      this.$router.push({
        name: 'update-customer',
        query: { id: item.id },
      });
    },
    deleteItem: async function (item) {
      ApiService.setHeader();
      ApiService.delete(`customers?id=${item.id}`)
        .then(() => {
          makeToastSuccess('Xóa thành công');
          this.fetchData();
        })
        .catch(() => {
          makeToastFaile('Không thể xóa khách hàng');
        });
    },
    showDeleteAlert: function (item) {
      Swal.fire({
        title: 'Xóa khách hàng!',
        text: 'Bạn có chắc muốn xóa khách hàng này không ?',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: 'rgb(221, 51, 51)',
        cancelButtonColor: '#E5EAEE',
        confirmButtonText: 'Xóa',
        cancelButtonText: '<span style="color:#464E5F">Hủy</span>',
        reverseButtons: true,
        showClass: {
          popup: 'animate__animated animate__fadeInDown',
        },
        hideClass: {
          popup: 'animate__animated animate__fadeOutUp',
        },
      }).then((result) => {
        if (result.value) {
          this.deleteItem(item);
        }
      });
    },
    onContext(ctx) {
      // // The date formatted in the locale, or the `label-no-date-selected` string
      // this.formatted = ctx.selectedFormatted
      // // The following will be an empty string until a valid date is entered
      if (ctx.selectedYMD == '') {
        this.customerDOB = '';
      } else {
        this.customerDOB = ctx.selectedYMD;
      }
    },
    getCustomerType(value) {
      switch (value) {
        case CUSTOMER_TYPE.RETAIL_CUSTOMER:
          return 'Khách lẻ';
        case CUSTOMER_TYPE.WHOLESALE_CUSTOMER:
          return 'Khách buôn';
        case CUSTOMER_TYPE.AGENCY:
          return 'Đại lý';
        case CUSTOMER_TYPE.VIP:
          return 'Khách VIP';
        case CUSTOMER_TYPE.KOL:
          return 'KOL';
        default:
          return '';
      }
    },
    getCustomerGender(value) {
      switch (value) {
        case 0:
          return 'Chưa xác định';
        case 1:
          return 'Nam';
        case 2:
          return 'Nữ';
        default:
          return '--';
      }
    },
    excelModal: async function () {
      this.optionsHeaderDefault = [
        { text: 'Khách hàng', value: 'fullName', disabled: true },
        { text: 'Số điện thoại', value: 'phoneNo', disabled: true },
      ];
      this.selectedHeader = [
        'fullName',
        'type',
        'gender',
        'phoneNo',
        'totalBuyedAmount',
        'totalBuyedNo',
      ];
      this.optionsHeader = [
        { text: 'Khách hàng', value: 'fullName', disabled: true },
        { text: 'Loại', value: 'type', disabled: false },
        { text: 'Giới tính', value: 'gender', disabled: false },
        { text: 'Điện thoại', value: 'phoneNo', disabled: true },
        { text: 'Tổng tiền', value: 'totalBuyedAmount', disabled: false },
        { text: 'Số lần mua', value: 'totalBuyedNo', disabled: false },
      ];
      this.$refs['excel-modal'].show();
    },
    reportClick: async function () {
      let data = {
        items: this.customers,
        headers: this.selectedHeader,
        params: null,
      };
      if (this.selectedRowExcel !== 'row') {
        data.headers = this.optionsHeader.map((x) => x.value);
      }
      if (this.selectedListExcel !== 'current') {
        if (
          this.selectedCategory !== null ||
          this.customerName !== '' ||
          this.selectedStatus !== '' ||
          this.selectedProductType !== ''
        ) {
          this.childProductId = '';
          this.parentProductId = '';
        }
        if (this.selectedStore === null) {
          this.selectedStore = 0;
        }
        data.params = {
          name: this.customerName,
          limit: 1000000,
          page: 1,
          categoryId: this.selectedCategory,
          currentSort: this.currentSort,
          currentSortDir: this.currentSortDir,
          status: this.selectedStatus,
          productType: this.selectedProductType,
          parentProductId: this.parentProductId,
          childProductId: this.childProductId,
          storeId: this.selectedStore,
        };
        data.items = [];
      }
      let baseUrl = BASE_URL;

      // let baseUrl = 'http://localhost:3000/api/';
      let url = `${baseUrl}customer/export-excel`;
      await axios({
        url: url,
        method: 'POST',
        responseType: 'blob',
        data: data,
      })
        .then((response) => {
          let headerLine = response.headers['content-disposition'];
          let startFileNameIndex = headerLine.indexOf('"') + 1;
          let endFileNameIndex = headerLine.lastIndexOf('"');
          let filename = headerLine.substring(
            startFileNameIndex,
            endFileNameIndex,
          );
          var fileURL = window.URL.createObjectURL(new Blob([response.data]));
          var fileLink = document.createElement('a');
          fileLink.href = fileURL;
          fileLink.setAttribute('download', filename);
          document.body.appendChild(fileLink);

          fileLink.click();
        })
        .catch(async (error) => {
          const responseObj = JSON.parse(await error.response.data.text());
          return makeToastFaile(responseObj.message || 'Lỗi');
        });
    },
    showHeader() {
      if (this.selectedRowExcel === 'row') {
        this.showHeaderExcel = true;
      } else {
        this.showHeaderExcel = false;
      }
    },
    toggleAll(checked) {
      this.selectedHeader = checked
        ? this.optionsHeader.map((x) => x.value)
        : this.optionsHeaderDefault.map((x) => x.value);
    },
    getLinkDoc(item) {
      return `#/customers/update-customer?id=${item.id}`;
    },
  },
};
</script>
